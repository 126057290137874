import type { IAdvertsResp } from '../interface/adverts';
import type { IBookingState, IGEtBookingSlotsQuery, IGetBookingsSlotsResp } from '../interface/booking';
import type { IDocumentUrlResp } from '../interface/documents';
import type { IMakeEnquiryData } from '../interface/enquiry';
import type { INetworkResponse, IQuery } from '../interface/network';
import endpoints from './endpoints';
import { requestMaker } from './request';

// update resp type
async function getAdverts(
	query?: IQuery
): Promise<IAdvertsResp> {
	return requestMaker({
		type: 'GET',
		route: endpoints.ADVERTS,
		queryParams: query,
	});
}

async function getDocumentUrl(key: string): Promise<IDocumentUrlResp> {
	return requestMaker({
		type: 'GET',
		route: endpoints.GET_DOCUMENT_LINK + '/' + key.replaceAll('/', '%2F'),
	});
}

async function makeEnquiry(data: IMakeEnquiryData): Promise<INetworkResponse> {
	return requestMaker({
		type: 'POST',
		data: data,
		route: endpoints.ENQUIRY,
	});
}

async function makeBooking(data: IBookingState): Promise<INetworkResponse> {
	return requestMaker({
		type: 'POST',
		data: data,
		route: endpoints.BOOKINGS_GUEST,
	});
}

async function getBookingsSlots(query: IGEtBookingSlotsQuery): Promise<IGetBookingsSlotsResp> {
	return requestMaker({
		type: 'GET',
		route: endpoints.BOOKINGS + '/slots',
		queryParams: query
	});
}

export default {
	getAdverts,
	getDocumentUrl,
	makeEnquiry,
	makeBooking,
	getBookingsSlots
}

import React, {
  type FC,
  useEffect,
  useState,
  type DetailedHTMLProps,
  type ImgHTMLAttributes,
} from 'react'
import api from '../../network/api'
import validator from 'validator'

interface ImagePreviewerProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  url: string
}

const ImagePreviewer: FC<ImagePreviewerProps> = ({ url, ...props }) => {
  const [link, setLink] = useState<string>('')

  const getDocumentLink = async (): Promise<any> => {
    try {
      const { data } = await api.getDocumentUrl(url)
      setLink(data)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (url) {
      if (validator.isURL(url)) {
        setLink(url)
      } else {
        getDocumentLink()
      }
    }
  }, [])

  return <img src={link} className="img-fluid" {...props} />
}

export default ImagePreviewer

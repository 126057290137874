import React, { useState, type FC } from 'react'
import { Collapse } from 'react-bootstrap'
import styles from './faq.module.css'
import { combineClassName } from '../../util/utilMethods'

interface CollapsibleProps {
  question: string
  answer: string
}

const Collapsible: FC<CollapsibleProps> = ({ question, answer }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.collapsible_container}>
      <p aria-expanded={open} className={styles.collapsible_header}>
        <span>{question}</span>
        <span
          onClick={() => {
            setOpen(!open)
          }}
        >
          {open ? (
            <span className={combineClassName('text-primary', styles.minus)}>
              &#8722;
            </span>
          ) : (
            <span className={combineClassName('bg-primary', styles.plus)}>
              &#43;
            </span>
          )}
        </span>
      </p>
      <Collapse in={open}>
        <div className={styles.answer}>{answer}</div>
      </Collapse>
    </div>
  )
}

export default Collapsible

import React, { useContext, type FC, useState } from 'react'
import styles from './bookings.module.css'
import { Button } from 'react-bootstrap'
import Scroll from '../../assets/svgs/scroll.svg'
import { combineClassName } from '../../util/utilMethods'
import Icon from '../../Components/Icon'
import { BookingDispatch } from '../../context/bookingContext'
import WhatsappModal from '../../Components/WhatsappModal'
import BookingModal from '../../Components/BookingModal'

const Bookings: FC = () => {
  const { setBookingValue, clearBooking } = useContext(BookingDispatch)

  const [showWhatsapp, setShowWhatsapp] = useState(false)
  const [book, setBook] = useState(false)

  const toggleWhatsappModal = (): void => {
    setShowWhatsapp(!showWhatsapp)
  }

  const toggleBookingModal = (): void => {
    setBook(!book)
    clearBooking()
  }

  const makeBooking = (activity: string): void => {
    setBook(!book)
    setBookingValue({ activity })
  }
  return (
    <>
      <WhatsappModal
        show={showWhatsapp}
        onHide={toggleWhatsappModal}
        handleButtonClck={toggleWhatsappModal}
      />
      <BookingModal addBooking={book} toggleBookingModal={toggleBookingModal} />
      <main className={styles.container}>
        <div className={styles.scroll_wrapper}>
          <div
            className={styles.scroll_image}
            onClick={() => {
              const div = document.getElementById('slide')
              div?.scroll(0, div.offsetHeight)
            }}
          >
            <img src={Scroll} alt="scroll_icon" className="w-100 img-fluid" />
          </div>
          <p className={styles.scroll_text}>Scroll</p>
        </div>
        <section className={styles.hero}>
          <h2>
            Embark On Unforgettable
            <br />
            Experiences
          </h2>
          <p>
            Discover a world of excitement and relaxation. Secure your
            <br />
            HEREL Play experience today
          </p>
        </section>
        <section className={styles.slide_wrapper} id="slide">
          <div className={combineClassName(styles.card1, styles.card)}>
            <p className={styles.card_text}>
              <span>Active</span>
              <span>
                <Icon name="arrow-right" />
              </span>
            </p>
            <div className={styles.card_overLay}>
              <h2>Active</h2>
              {/* <h3>Kick, dribble, and score your way to victory!</h3> */}
              <p>
                &quot;Experience the Thrill of the Game on Our State-of-the-Art
                5-Aside Football Pitch. Tailored for Enthusiastic Individuals
                and Dedicated Training Clubs. Let&apos;s Train and PLAY!
              </p>
              <Button
                variant="warning"
                className={styles.card_btn}
                onClick={() => {
                  makeBooking('football_pitch')
                }}
              >
                Explore
              </Button>
            </div>
          </div>
          <div className={combineClassName(styles.card2, styles.card)}>
            <p className={styles.card_text}>
              <span>Swim</span>
              <span>
                <Icon name="arrow-right" />
              </span>
            </p>
            <div className={styles.card_overLay}>
              <h2>Swim</h2>
              {/* <h3>Eat in a KONTAINER</h3> */}
              <p>
                Dive into a world of sheer indulgence. With its elegant design
                and size, our pool offers all you need for respite, training,
                wellness, and exhilaration.
              </p>
              <Button
                variant="warning"
                className={styles.card_btn}
                onClick={() => {
                  makeBooking('swimming_pool')
                }}
              >
                Book Your Session
              </Button>
            </div>
          </div>
          <div className={combineClassName(styles.card3, styles.card)}>
            <p className={styles.card_text}>
              <span>Gaming</span>
              <span>
                <Icon name="arrow-right" />
              </span>
            </p>
            <div className={styles.card_overLay}>
              <h2>Gaming</h2>
              {/* <h3>Submerge Yourself in Paradise</h3> */}
              <p>
                Are you aiming for a strategic shot on the snooker table or
                seeking the thrill of classic and modern arcade games, our game
                spot promises endless excitement and entertainment.
              </p>
              <Button
                variant="warning"
                className={styles.card_btn}
                // onClick={toggleWhatsappModal}
              >
                Coming soon
              </Button>
            </div>
          </div>
          <div className={combineClassName(styles.card4, styles.card)}>
            <p className={styles.card_text}>
              <span>Beauty</span>
              <span>
                <Icon name="arrow-right" />
              </span>
            </p>
            <div className={styles.card_overLay}>
              <h2>Beauty</h2>
              {/* <h3>Enjoy a clean, safe, and Gaming Experience </h3> */}
              <p>
                Step into our exclusive sanctuary designed to cater to all your
                beauty and wellness needs. Our Hair and Beauty Salon is where
                the artistry of transformation and self-care come together in
                perfect harmony.
              </p>
              <Button
                variant="warning"
                className={styles.card_btn}
                onClick={toggleWhatsappModal}
              >
                Book Your Appointment
              </Button>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Bookings

import React, { type ReactNode, createContext, useReducer } from 'react'
import { type MobileMenuState } from '../../interface/mobileMenu'
import MobileMenuReducer from './reducer'
import { TOGGLE_MOBILE_MENU } from './types'

const initialState: MobileMenuState = {
  show: false,
}
interface IMobileMenuDispatch {
  toggleMobileMenu: () => void
}

export const MobileMenuContext = createContext<MobileMenuState>(initialState)
export const MobileMenuDispatch = createContext<IMobileMenuDispatch>({
  toggleMobileMenu: () => {},
})

export const MobileMenuProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [state, dispatch] = useReducer(MobileMenuReducer, initialState)

  const toggleMobileMenu = (): void => {
    dispatch({ type: TOGGLE_MOBILE_MENU })
  }

  return (
    <MobileMenuContext.Provider
      value={{
        show: state.show,
      }}
    >
      <MobileMenuDispatch.Provider
        value={{
          toggleMobileMenu,
        }}
      >
        {children}
      </MobileMenuDispatch.Provider>
    </MobileMenuContext.Provider>
  )
}

import React, { useState, type FC, type ChangeEvent } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from '../home.module.css'
import { combineClassName, phoneNumberValidation } from '../../../util/utilMethods'
import Input from '../../../Components/Input'
import TextArea from '../../../Components/TextArea'
import { Button } from 'react-bootstrap'
import type { IMakeEnquiryData } from '../../../interface/enquiry'
import { toast } from 'react-toastify'
import api from '../../../network/api'

const initialState = {
  name: '',
  email: '',
  type: 'general',
  phone: '',
  comment: '',
}

const ContactForm: FC = () => {
  const [enquiry, setEnquiry] = useState<IMakeEnquiryData>(initialState)
  const [human, setHuman] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value
    if (e.target.name === 'phone') {
      value = phoneNumberValidation(value)
    }
    setEnquiry({ ...enquiry, [e.target.name]: value })
  }
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setEnquiry({ ...enquiry, [e.target.name]: e.target.value })
  }

  const validate = (): boolean =>
    !human || !enquiry.email || !enquiry.name || !enquiry.comment || loading

  const onSubmit = async (): Promise<void> => {
    setLoading(true)
    try {
      const resp = await api.makeEnquiry(enquiry)
      toast.success(resp?.message ?? 'Your message has been received')
      setEnquiry(initialState)
    } catch (error: any) {
      toast.error(error?.message ?? 'Something went wrong')
    }
    setLoading(false)
  }

  return (
    <div className={combineClassName('w-100', styles.contact_form)}>
      <div>
        <h3>Get in touch</h3>
        <p>Getting in touch with us is easy</p>
        <Input
          className={combineClassName('bg-white mb-3', styles.contact_input)}
          placeholder="Your name"
          handleInputChange={handleChange}
          value={enquiry.name}
          name="name"
        />
        <Input
          className={combineClassName('bg-white mb-3', styles.contact_input)}
          placeholder="Email address"
          handleInputChange={handleChange}
          value={enquiry.email}
          name="email"
        />
         <Input
          className={combineClassName('bg-white mb-3', styles.contact_input)}
          placeholder="Phone number"
          handleInputChange={handleChange}
          value={enquiry.phone}
          name="phone"
          maxLength={11}
        />
        <TextArea
          className={combineClassName('bg-white mb-3', styles.contact_input)}
          placeholder="Comment"
          handleTextChange={handleTextAreaChange}
          name="comment"
          value={enquiry.comment}
        />
        <div className="mb-3">
          <ReCAPTCHA
            sitekey="6LcgWacoAAAAAHaSJ8cCGtNJmKx-OMtPXi4nd0th"
            onChange={() => {
              setHuman(!human)
            }}
          />
        </div>
        <Button
          variant="warning"
          className="text-black fw-bold"
          size="lg"
          disabled={validate()}
          onClick={(): any => {
            onSubmit()
          }}
        >
          {loading ? 'Please wait...' : ' Submit'}
        </Button>
      </div>
    </div>
  )
}

export default ContactForm

import type { IBookingAction, IBookingState } from '../../interface/booking';
import { CLEAR_BOOKING, SET_BOOKING_VALUE } from './types';

export const bookingInitialState = {
    activity: '',
    date: '',
    note: '',
    startTime: '',
    endTime: '',
    email: '',
    phone: '',
    lastName: '',
    firstName: '',
}

export default (state: IBookingState, action: IBookingAction): IBookingState => {
    switch (action.type) {
        case SET_BOOKING_VALUE:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_BOOKING:
            return bookingInitialState
        default:
            return state;
    }
};

import React, { useState, type FC, useContext } from 'react'
import { combineClassName } from '../../util/utilMethods'
import styles from './home.module.css'
import { Button } from 'react-bootstrap'
import Scroll from '../../assets/svgs/scroll.svg'
import FAQ from '../../Components/FAQ'
import Footer from '../../Components/Footer'
import WhatsappModal from '../../Components/WhatsappModal'
import BookingModal from '../../Components/BookingModal'
import ContactForm from './Components/ContactForm'
import { BookingDispatch } from '../../context/bookingContext'
import Icon from '../../Components/Icon'
import { Link, useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const Home: FC = () => {
  const navigate = useNavigate()
  const { setBookingValue, clearBooking } = useContext(BookingDispatch)

  const [showWhatsapp, setShowWhatsapp] = useState(false)
  const [book, setBook] = useState(false)

  const toggleWhatsappModal = (): void => {
    setShowWhatsapp(!showWhatsapp)
  }

  const toggleBookingModal = (): void => {
    setBook(!book)
    clearBooking()
  }

  const makeBooking = (activity: string): void => {
    setBook(!book)
    setBookingValue({ activity })
  }

  const first = 'Relax, Refresh, PLAY.'
  return (
    <>
      <WhatsappModal
        show={showWhatsapp}
        onHide={toggleWhatsappModal}
        handleButtonClck={toggleWhatsappModal}
      />
      <BookingModal addBooking={book} toggleBookingModal={toggleBookingModal} />
      <div className={styles.home}>
        <div className={combineClassName('text-primary', styles.right_bar)}>
          <div className="cursor-pointer">
            <Link to="/bookings" className="text-decoration-none text-reset">
              <p className={styles.right_bar_items}>
                <Icon name="calendar" />
              </p>
              <p className={styles.right_bar_items}>Book</p>
            </Link>
          </div>
          <div className="cursor-pointer">
            <HashLink to="/#footer" className="text-decoration-none text-reset">
              <p className={styles.right_bar_items}>
                <Icon name="call" />
              </p>
              <p className={styles.right_bar_items}>Call</p>
            </HashLink>
          </div>
          <div className="cursor-pointer">
            <HashLink
              to="/#contact"
              className="text-decoration-none text-reset"
            >
              <p className={styles.right_bar_items}>
                <Icon name="chat" />
              </p>
              <p className={styles.right_bar_items}>Chat</p>
            </HashLink>
          </div>
        </div>
        <section className={combineClassName(styles.hero_wrapper)}>
          <div className={styles.hero}>
            <div className={styles.hero_child}>
              <h2>
                {first.split('').map(function (char, index) {
                  const style = { animationDelay: 0.5 + index / 10 + 's' }
                  return (
                    <span
                      aria-hidden="true"
                      key={index}
                      style={style}
                      className={styles.animated_title}
                    >
                      {char}
                    </span>
                  )
                })}
              </h2>
              <p>Welcome to Lagos’ Premier Lifestyle Destination</p>
              <div className={styles.scroll_wrapper}>
                <div
                  className={styles.scroll_image}
                  onClick={() => {
                    window.scroll(0, document.body.offsetHeight)
                  }}
                >
                  <img
                    src={Scroll}
                    alt="scroll_icon"
                    className="w-100 img-fluid"
                  />
                </div>
                <p className={styles.scroll_text}>Scroll</p>
              </div>
            </div>
          </div>
        </section>
        <section
          className={combineClassName('text-center', styles.what_we_offer)}
        >
          <h3>HEREL Play at a Glance</h3>
          <p>
            Welcome to Play!
            <br />
            Step into Your Exclusive World of Leisure and Unparalleled
            Experiences. Indulge in Exquisite Dining Options and Pamper Yourself
            with Premium Beauty and Wellness Services, All Curated by Our Highly
            Skilled Concierge and Service Team.
          </p>
          <div className={styles.home_grid}>
            <div className={combineClassName(styles.grid, styles.grid1)}>
              <p className={styles.grid_text}>Dine</p>
              <div className={styles.grid_overLay}>
                <h3>Dine</h3>
                <p>
                  Let&apos;s take you on a culinary journey like no other. With
                  exquisite flavours and impeccably crafted dishes, our dining
                  experience awakens the palate for adults and children alike.
                </p>
                <Button
                  className={styles.grid_btn}
                  onClick={() => {
                    navigate('/dine')
                  }}
                >
                  Explore
                </Button>
              </div>
            </div>
            <div className={combineClassName(styles.grid, styles.grid2)}>
              <p className={styles.grid_text}>Swim</p>
              <div className={styles.grid_overLay}>
                <h3>Swim</h3>
                <p>
                  Dive into a world of sheer indulgence. With its elegant design
                  and size, our pool offers all you need for respite, training,
                  wellness, private classes and exhilaration.
                </p>
                <Button
                  className={styles.grid_btn}
                  onClick={() => {
                    makeBooking('swimming_pool')
                  }}
                >
                  Book Your Session
                </Button>
              </div>
            </div>
            <div className={combineClassName(styles.grid, styles.grid3)}>
              <p className={styles.grid_text}>Gaming</p>
              <div className={styles.grid_overLay}>
                <h3>Gaming</h3>
                <p>
                  Are you aiming for a strategic shot on the snooker table or
                  seeking the thrill of classic and modern arcade games, our
                  game spot promises endless excitement and entertainment.
                </p>
                <Button
                  className={styles.grid_btn}
                  // onClick={toggleWhatsappModal}
                >
                  Coming soon
                </Button>
              </div>
            </div>
            <div className={combineClassName(styles.grid, styles.grid4)}>
              <p className={styles.grid_text}>Dance</p>
              <div className={styles.grid_overLay}>
                <h3>Dance</h3>
                <p>
                  Elevate Your Nightlife Experience by Immersing Yourself in the
                  Vibrant Pulse of Nigeria&apos;s Elite Party Scene. It&apos;s
                  time to dance, socialize, and revel in the finest
                  entertainment Lagos has to offer. Join us for a night of
                  unforgettable celebration.
                </p>
                <Button
                  className={styles.grid_btn}
                  onClick={toggleWhatsappModal}
                >
                  Book Now
                </Button>
              </div>
            </div>
            <div className={combineClassName(styles.grid, styles.grid5)}>
              <p className={styles.grid_text}>Active</p>
              <div className={styles.grid_overLay}>
                <h3>Active</h3>
                <p>
                  &quot;Experience the Thrill of the Game on Our
                  State-of-the-Art 5-Aside Football Pitch. Tailored for
                  Enthusiastic Individuals and Dedicated Training Clubs.
                  Let&apos;s Train and PLAY!
                </p>
                <Button
                  className={styles.grid_btn}
                  onClick={() => {
                    makeBooking('football_pitch')
                  }}
                >
                  Book Pitch
                </Button>
              </div>
            </div>
          </div>
        </section>
        <FAQ />
        <section className={styles.contact} id="contact">
          <ContactForm />
          <div className={combineClassName('w-100', styles.map)}>
            <iframe
              className="w-100 h-100"
              title="HEREL Address"
              src="https://maps.google.com/maps?q=14b%20Ruxton Road,%20Ikoyi,%20Lagos&t=&z=13&ie=UTF8&iwloc=&output=embed"
            ></iframe>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default Home

import React, { useState, type FC, useEffect } from 'react'
import styles from './faq.module.css'
import { combineClassName } from '../../util/utilMethods'
import { faqData } from './faqData'
import Collapsible from './Collapsible'
// import KoreanFood from '../../assets/svgs/korean_food.svg'
import { Carousel, Spinner } from 'react-bootstrap'
import type { IAdvert } from '../../interface/adverts'
import api from '../../network/api'
import ImagePreviewer from '../ImagePreview'

const FAQ: FC = () => {
  const [adverts, setAderts] = useState<IAdvert[]>()
  const [loading, setLoading] = useState(false)

  const getAderts = async (): Promise<any> => {
    setLoading(true)
    try {
      const resp = await api.getAdverts()
      setAderts(resp.data)
    } catch (error) {
      setLoading(false)
      return error
    }
    setLoading(false)
  }

  useEffect(() => {
    getAderts()
  }, [])

  return (
    <section className={combineClassName(styles.container)} id="faq">
      <div className={styles.faq_wrapper}>
        <h3 className="text-primary" style={{ height: '10%' }}>
          Frequently asked questions
        </h3>
        <div className={styles.collapsible_wrapper}>
          {faqData.map((data, i: number) => (
            <div key={i}>
              <Collapsible question={data.question} answer={data.answer} />
            </div>
          ))}
        </div>
      </div>
      {loading ? (
        <Spinner variant="primary" />
      ) : (
        <Carousel
          controls={false}
          indicators={false}
          className={styles.carousels}
          fade
        >
          {/* <Carousel.Item interval={3000} className="h-100 w-100">
          <img src={KoreanFood} alt="" className="w-100 h-100 img-fluid" />
        </Carousel.Item> */}
          {adverts?.map((advert, index) => {
            if (advert.image) {
              return (
                <Carousel.Item
                  interval={3000}
                  className={`h-100 w-100 ${advert?.link && 'cursor-pointer'}`}
                  key={index}
                  onClick={() => {
                    if (advert?.link) {
                      const link = advert?.link?.startsWith('http')
                        ? advert?.link
                        : `https://${advert?.link}`

                      window.open(link, '_blank', 'noopener,noreferrer')
                    }
                  }}
                >
                  <ImagePreviewer
                    url={advert.image}
                    className="w-100 h-100 img-fluid"
                  />
                </Carousel.Item>
              )
            } else {
              return null
            }
          })}
        </Carousel>
      )}
    </section>
  )
}

export default FAQ

import Home from '../Screens/Home';
import Bookings from '../Screens/Bookings';
import Dine from '../Screens/Dine';
// import Membership from '../Screens/Membership';
// import Restaurant from '../Screens/Restaurant';

const publicRoutes = [
  { path: '/', element: Home },
  { path: '/bookings', element: Bookings },
  { path: '/dine', element: Dine },
  // { path: '/membership', element: Membership },
  // { path: '/restaurant', element: Restaurant }
]

export default publicRoutes;

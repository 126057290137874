import React, { type FC } from 'react'
import styles from './NoAccess.module.css'
import Caution from '../../assets/svgs/caution-icon.svg'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const NotFound: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.noAccessCard}>
        <img src={Caution} alt="Warning" />
        <div>
          <h4>Page Not Found!</h4>
          <p>The page you are looking does not exist</p>
          <Link to="/">
            <Button>Go to homepage</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound

import React, { useContext } from 'react'
import Logo from '../../assets/svgs/HPlayWhiteFull.svg'
import { Button } from 'react-bootstrap'
import styles from './header.module.css'
import { combineClassName } from '../../util/utilMethods'
import { Link, NavLink } from 'react-router-dom'
import {
  MobileMenuContext,
  MobileMenuDispatch,
} from '../../context/mobileMenuContext'
import { HashLink } from 'react-router-hash-link'

const Header = (): JSX.Element => {
  const { show } = useContext(MobileMenuContext)
  const { toggleMobileMenu } = useContext(MobileMenuDispatch)

  const navClass = ({ isActive }: { isActive: any }): string =>
    combineClassName(
      'text-decoration-none text-reset',
      isActive ? 'text-decoration-underline' : ''
    )
  return (
    <nav
      className={combineClassName(
        'd-flex justify-content-between px-2 px-lg-5 align-items-center',
        styles.container
      )}
    >
      <div
        className={combineClassName(
          styles.hamburger,
          show ? styles.change : ''
        )}
        onClick={toggleMobileMenu}
      >
        <div className={styles.bar1}></div>
        <div className={styles.bar2}></div>
        <div className={styles.bar3}></div>
      </div>
      <div className={styles.img_container} onClick={toggleMobileMenu}>
        <Link to="/">
          <img src={Logo} alt="header logo" className="img-fluid w-100 h-100" />
        </Link>
      </div>
      <ul className="list-unstyled d-flex gap-5 pt-3">
        <li>
          <NavLink to="/bookings" className={navClass}>
            Bookings
          </NavLink>
        </li>
        <li>
          <NavLink to="/dine" className={navClass}>
            Dine
          </NavLink>
        </li>
        {/* <li>
          <Link to="/restaurant" className="text-decoration-none text-reset">
            Restaurant
          </Link>
        </li> */}
        <li>
          <HashLink className="text-decoration-none text-reset" to="/#faq">
            FAQs
          </HashLink>
        </li>
      </ul>
      <div>
        <HashLink to="/#contact">
          <Button className={styles.header_btn}>Contact us</Button>
        </HashLink>
      </div>
    </nav>
  )
}

export default Header

import { type MobileMenuAction, type MobileMenuState } from '../../interface/mobileMenu';
import { TOGGLE_MOBILE_MENU } from './types';

export default (state: MobileMenuState, action: MobileMenuAction): MobileMenuState => {
    switch (action.type) {
        case TOGGLE_MOBILE_MENU:
            return {
                ...state,
                show: !state.show,
            };
        default:
            return state;
    }
};

import { getError } from '../util/error';

export const buildHeader = (
  secure?: boolean,
  headers?: any,
  isUpload?: boolean
): HeadersInit => {
  const token = localStorage.getItem('token');
  const header = {
    'Content-Type': 'application/json',
    // 'Cache-Control': 'no-cache',
    // 'Accept': '*/*',
  };
  if (secure) {
    const hh = {
      Authorization: `Bearer ${token}`,
      ...(headers ?? {}),
    };
    if (!isUpload) {
      Object.assign(header, hh);
    } else {
      return hh;
    }
  } else {
    if (!isUpload) {
      Object.assign(header, headers);
    } else {
      return headers;
    }
  }
  return header;
};

export const makeUrlKeyValuePairs = (json: Record<string, any>): string => {
  if (!json || Object.keys(json).length < 1) {
    return '';
  }
  const keys: string[] = Object.keys(json);
  let query = '?';
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    query += encodeURIComponent(key) + '=' + encodeURIComponent(json[key]) + '&';
  }
  return query.replace(/&$/g, '');
};

interface RequestObject {
  type: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  isSecure?: boolean
  queryParams?: Record<string, any>
  onResponse?: () => void
  data?: Record<string, any> | FormData
  route: string
  headers?: Record<string, any>
};

export async function requestMaker({
  onResponse,
  data,
  type = 'GET',
  queryParams,
  route,
  isSecure = false,
  headers,
}: RequestObject): Promise<any> {
  // let response: Response;
  // Handle get request with params
  let routePlusParams = route;
  if (queryParams) {
    routePlusParams += makeUrlKeyValuePairs(queryParams);
  }

  const hh = buildHeader(isSecure, headers, data instanceof FormData);
  let body;
  if (data instanceof FormData) {
    body = data;
  } else {
    body = JSON.stringify(data);
  }
  const response: Response = await fetch(routePlusParams.trim(), {
    method: type,
    headers: hh,
    body:
      type === 'POST' || type === 'DELETE' || type === 'PUT' || type === 'PATCH'
        ? body
        : null,
  });

  // TODO: log responses that are not 200
  const response2 = response.clone();
  try {
    if (response && response.ok) {
      const responseJSON = await response.json();
      const v = { ...responseJSON, statusCode: response.status };
      return v;
    }
    // throw response;
    return getError(response);
  } catch (error: any) {
    try {
      const dd = await response2.text();
      return dd;
    } catch (err: any) {
      return getError(err);
    }
  }
}

/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
  type ChangeEvent,
  cloneElement,
  type CSSProperties,
  type DetailedHTMLProps,
  type FC,
  type HTMLAttributes,
  type InputHTMLAttributes,
  type ReactElement,
  useState,
} from 'react'
import { combineClassName } from '../../util/utilMethods'
import styles from './Input.module.css'

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  /**
   * The input label which appears at the top
   */
  inputLabel?: string

  /**
   * The html input wrapper inline css
   * @note the wrapper is the top layer
   */
  containerStyle?: CSSProperties

  /**
   * The html input wrapper css class
   * @note the wrapper is the top layer
   */
  containerClassName?: string
  /**
   * Is input valid
   */
  validation?: boolean

  errorMessage?: string

  editable?: boolean

  body?: string | ReactElement<HTMLAttributes<HTMLHtmlElement>>

  handleInputChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const Input: FC<InputProps> = ({
  className,
  body,
  inputLabel,
  editable,
  handleInputChange,
  containerStyle,
  defaultValue,
  containerClassName,
  validation = true,
  errorMessage,
  ...props
}) => {
  const [touched, setTouched] = useState(false)
  const renderBody = (): any => {
    if (body) {
      if (typeof body === 'string') {
        return <p className={styles.editable}>{body}</p>
      } else {
        return cloneElement(body, {
          className: combineClassName(body.props.className, styles.editable),
        })
      }
    } else if (editable ?? typeof editable === 'undefined') {
      return (
        <input
          onBlur={() => {
            setTouched(true)
          }}
          {...props}
          defaultValue={defaultValue}
          onChange={handleInputChange}
          className={combineClassName(className, styles.input)}
        />
      )
    } else {
      return <p className={styles.editable}>{props.value}</p>
    }
  }

  return (
    <>
      <div className={containerClassName} style={containerStyle}>
        {inputLabel ? <p className={styles.input_label}>{inputLabel}</p> : null}
        {renderBody()}
      </div>

      {validation && touched ? (
        <span className={styles.valid_errormessage}>{errorMessage}</span>
      ) : null}
    </>
  )
}

export default Input

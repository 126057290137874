export const faqData = [
    {
        question: 'What activities and facilities does HEREL Play offer?',
        answer: 'At HEREL Play, you get access to entertainment and wellness activities including a restaurant, lounge, swimming pool, 5-a-side football pitch, arcade and a snooker bar. There\'s something for everyone to enjoy!'
    },
    {
        question: 'Can I order food from the restaurant to be delivered to my doorstep or preferred location?',
        answer: 'Yes, absolutely! We offer convenient doorstep delivery services for all our menu items. Simply place your order by giving us a call or reach out via WhatsApp on +2349169830299, and we\'ll ensure your delicious food is promptly delivered to your specified location​'
    },
    {
        question: 'What are the operating hours of HEREL Play?',
        answer: 'HEREL Play is open from 7:00 AM to 2:00 AM everyday (Monday to Sunday)'
    },
    {
        question: 'Do I need to make a reservation for activities or services?',
        answer: 'While walk-ins are welcome, we recommend making reservations for certain activities, especially during peak hours and busy days, to ensure availability.'
    },
    {
        question: 'Is there an age limit for certain activities?',
        answer: 'People of all ages are welcome to play at HEREL Play. The facility does become 18+ from 6pm everyday for safety reasons'
    },
    {
        question: 'Are outside food and drinks allowed inside the premises?',
        answer: 'We have a variety of exquisite food and beverage options made by our exceptional chefs, so you have no need to worry about outside catering.'
    },
    {
        question: 'Is there ample parking available for visitors?',
        answer: 'Yes, we have ample parking facilities available for our visitors. Parking is complimentary for guests during their stay.'
    },
    {
        question: 'Can I host private events or parties at HEREL Play?',
        answer: 'HEREL Play has been designed to accommodate a wide range of gatherings and occasions. Kindly reach out to our experience team on hello@herelplay.com or +2349169830299​'
    },
    {
        question: 'Do you offer group discounts or packages?',
        answer: 'We want you to Play with friends and family, which is why we offer group discounts across different activities on site. Feel free to reach out to our experience team on hello@herelplay.com or +2349169830299​'
    },
    {
        question: 'Are there changing rooms and shower facilities on-site for guests to use?',
        answer: 'We provide clean and well-maintained changing rooms and shower facilities for your convenience.'
    },
    {
        question: 'Can I request private swimming classes?',
        answer: 'Certainly! We offer private swimming lessons tailored to your individual needs and schedule. Whether you\'re a beginner looking to learn or an experienced swimmer aiming to refine specific techniques, our certified instructors will personalize lessons to suit your skill level and goals. Reach out to our experience team on hello@herelplay.com or +2349169830299 for more enquiries.​'
    }
]

import React, { type FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { combineClassName } from '../../util/utilMethods'
import styles from './whatsappmodal.module.css'

interface WhatsappModalProps {
  show: boolean
  onHide: (value?: any) => any
  handleButtonClck: (value?: any) => any
}

const WhatsappModal: FC<WhatsappModalProps> = ({
  show,
  onHide,
  handleButtonClck,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered dialogClassName={styles.dialog}>
      <Modal.Body
        className={combineClassName(
          'd-flex flex-column justify-content-center align-items-center gap-2 text-center',
          styles.wrapper
        )}
      >
        <h3 className="text-primary">You will be redirected to WhatsApp</h3>
        <p>
          To conclude your booking, you will be redirected to WhatsApp for
          further assistance.
        </p>
        <Button
          onClick={() => {
            window.location.href = 'https://wa.me/+2349169830299'
            handleButtonClck()
          }}
          className="px-lg-5"
        >
          Continue to WhatsApp
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default WhatsappModal

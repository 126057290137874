import React, { useState, type FC } from 'react'
import { Modal } from 'react-bootstrap'
import styles from './bookingmodal.module.css'
import { combineClassName } from '../../util/utilMethods'
import Step1 from './Components/Step1'
import Step2 from './Components/Step2'
import Step3 from './Components/Step3'
import SuccessModal from '../SuccessModal'

export enum StepsEnum {
  one = 1,
  two = 2,
  three = 3,
}

interface BookingModalProps {
  addBooking: boolean
  toggleBookingModal: () => void
}

const BookingModal: FC<BookingModalProps> = ({
  addBooking,
  toggleBookingModal,
}) => {
  const [step, setStep] = useState<StepsEnum>(StepsEnum.one)
  const [success, setSuccess] = useState(false)

  const toggleSuccess = (): void => {
    setSuccess(!success)
  }

  return (
    <>
      <SuccessModal
        show={success}
        onHide={toggleSuccess}
        title="Booked"
        description="Activity successfully booked, a customer representative will contact you to finalise payment."
      />
      <Modal
        centered
        show={addBooking}
        onHide={() => {
          toggleBookingModal()
          setStep(StepsEnum.one)
        }}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className={styles.steps_header}>
            <div
              className={combineClassName(
                styles.stepper_wrapper,
                step > StepsEnum.one ? styles.stepper_wrapper_2 : '',
                step === StepsEnum.three ? styles.stepper_wrapper_last : ''
              )}
            >
              <div
                className={combineClassName(
                  styles.stepper,
                  styles.stepper_active
                )}
              >
                <p
                  className={combineClassName(
                    step > StepsEnum.one ? styles.stepper_icon : ''
                  )}
                ></p>
              </div>
              <div
                className={combineClassName(
                  styles.stepper,
                  step > StepsEnum.one ? styles.stepper_active : ''
                )}
              >
                <p
                  className={step > StepsEnum.two ? styles.stepper_icon : ''}
                ></p>
              </div>
              <div
                className={combineClassName(
                  styles.stepper,
                  step === StepsEnum.three ? styles.stepper_active : ''
                )}
              >
                <p
                  className={step > StepsEnum.two ? styles.stepper_icon : ''}
                ></p>
              </div>
            </div>
            <div className={styles.stepper_texts}>
              <p>Choose time</p>
              <p className="ps-4">Your info</p>
              <p>Confirm booking</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === StepsEnum.one ? (
            <Step1 setStep={setStep} />
          ) : step === StepsEnum.two ? (
            <Step2 setStep={setStep} />
          ) : (
            <Step3
              setStep={setStep}
              toggleSuccess={toggleSuccess}
              toggleBookingModal={toggleBookingModal}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BookingModal

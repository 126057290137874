import React, {
  type Dispatch,
  type FC,
  type SetStateAction,
  useContext,
  useState,
} from 'react'
import styles from '../bookingmodal.module.css'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import Icon from '../../../Components/Icon'
import { StepsEnum } from '..'
import {
  BookingContext,
  BookingDispatch,
} from '../../../context/bookingContext'
import type { ISlot } from '../../../interface/booking'
import api from '../../../network/api'
import { toast } from 'react-toastify'
import { convertTime } from '../../../util/utilMethods'
import moment from 'moment'

interface Step1Props {
  setStep: Dispatch<SetStateAction<StepsEnum>>
}

const Step1: FC<Step1Props> = ({ setStep }) => {
  const { setBookingValue } = useContext(BookingDispatch)
  const booking = useContext(BookingContext)

  const [loading, setLoading] = useState(false)
  const [slots, setSlots] = useState<ISlot[]>()

  const getSlots = async (activity: string, date: string): Promise<void> => {
    setLoading(true)
    try {
      const resp = await api.getBookingsSlots({
        activity,
        date,
      })
      setSlots(resp?.data)
    } catch (error: any) {
      toast.error(error?.message ?? 'Something went wrong')
    }
    setLoading(false)
  }

  return (
    <main className="d-flex justify-content-center gap-4 flex-wrap">
      <div className={styles.date_time}>
        <h2>Available date</h2>
        <p>Please choose a date below</p>
        <div className={styles.calendar_wrapper}>
          <Calendar
            calendarType="gregory"
            className={styles.calendar}
            tileClassName={styles.calendar_tile}
            minDate={new Date()}
            nextLabel={<Icon name="greater_than" />}
            next2Label={<Icon name="greater_than" />}
            prevLabel={<Icon name="less_than" />}
            prev2Label={<Icon name="less_than" />}
            onChange={(value: any) => {
              setBookingValue({ date: value })
              getSlots(booking?.activity, moment(value).format('DD-MM-YYYY'))
            }}
            value={booking?.date}
          />
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2>Available time</h2>
        <p>Please choose a time below</p>
        {loading ? (
          <p className="py-5 m-auto text-center text-primary">Please wait...</p>
        ) : !booking?.date ? (
          <p className="py-5 m-auto text-center text-primary">
            Please choose a date
          </p>
        ) : slots && slots?.length < 1 ? (
          <p className="py-5 m-auto text-center text-primary">
            No available slot, please choose a new date
          </p>
        ) : (
          slots?.map((a: ISlot, i: number) => {
            if (a?.available) {
              return (
                <div
                  className={styles.time_tile}
                  style={{
                    backgroundColor:
                      booking.startTime === a?.time?.split(' ')[0]
                        ? '#DADADA'
                        : '#fff',
                  }}
                  key={i}
                  onClick={() => {
                    const value: any = { startTime: a?.time?.split(' ')[0] }
                    if (a?.time?.split(' ')[2]) {
                      value.endTime = a?.time?.split(' ')[2]
                    }
                    setBookingValue({ ...value })
                    if (booking.date) {
                      setStep(StepsEnum.two)
                    }
                  }}
                >
                  <p>{convertTime(a?.time?.split(' ')[0])}</p>
                  {a?.time?.split(' ')[2] ? (
                    <p>{` - ${convertTime(a?.time?.split(' ')[2])}`}</p>
                  ) : null}
                </div>
              )
            } else {
              return null
            }
          })
        )}
      </div>
    </main>
  )
}

export default Step1

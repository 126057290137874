import React, { type FC } from 'react'

interface IconProps {
  name:
    | 'arrow-right'
    | 'less_than'
    | 'greater_than'
    | 'calendar'
    | 'call'
    | 'location'
    | 'chat'
}

const Icon: FC<IconProps> = ({ name }) => {
  switch (name) {
    case 'arrow-right':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 12H19M19 12L12 5M19 12L12 19"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case 'less_than':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.86198 4.19531L5.05731 7.99998L8.86198 11.8046L9.80465 10.862L6.94264 7.99998L9.80465 5.13798L8.86198 4.19531Z"
            fill="#1C201F"
          />
        </svg>
      )
    case 'greater_than':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="16"
            height="16"
            fill="white"
            style={{ mixBlendMode: 'multiply' }}
          />
          <path
            d="M11 8L6.00005 13L5.30005 12.3L9.60005 8L5.30005 3.7L6.00005 3L11 8Z"
            fill="#525252"
          />
        </svg>
      )
    case 'calendar':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2225_9004)">
            <path
              d="M22.5 2.24766L16.4948 2.24768V0.75293C16.4948 0.338555 16.1591 0.00292969 15.7448 0.00292969C15.3304 0.00292969 14.9948 0.338555 14.9948 0.75293V2.2473H8.99475V0.75293C8.99475 0.338555 8.65913 0.00292969 8.24475 0.00292969C7.83038 0.00292969 7.49475 0.338555 7.49475 0.75293V2.2473H1.5C0.671625 2.2473 0 2.91893 0 3.7473V22.4973C0 23.3257 0.671625 23.9973 1.5 23.9973H22.5C23.3284 23.9973 24 23.3257 24 22.4973V3.7473C24 2.91928 23.3284 2.24766 22.5 2.24766ZM22.5 22.4973H1.5V3.7473H7.49475V4.50293C7.49475 4.91728 7.83038 5.25293 8.24475 5.25293C8.65913 5.25293 8.99475 4.91728 8.99475 4.50293V3.74768H14.9948V4.5033C14.9948 4.91768 15.3304 5.2533 15.7448 5.2533C16.1591 5.2533 16.4948 4.91768 16.4948 4.5033V3.74768H22.5V22.4973ZM17.25 11.9977H18.75C19.164 11.9977 19.5 11.6617 19.5 11.2477V9.74766C19.5 9.33366 19.164 8.99766 18.75 8.99766H17.25C16.836 8.99766 16.5 9.33366 16.5 9.74766V11.2477C16.5 11.6617 16.836 11.9977 17.25 11.9977ZM17.25 17.9973H18.75C19.164 17.9973 19.5 17.6617 19.5 17.2473V15.7473C19.5 15.3333 19.164 14.9973 18.75 14.9973H17.25C16.836 14.9973 16.5 15.3333 16.5 15.7473V17.2473C16.5 17.662 16.836 17.9973 17.25 17.9973ZM12.75 14.9973H11.25C10.836 14.9973 10.5 15.3333 10.5 15.7473V17.2473C10.5 17.6617 10.836 17.9973 11.25 17.9973H12.75C13.164 17.9973 13.5 17.6617 13.5 17.2473V15.7473C13.5 15.3337 13.164 14.9973 12.75 14.9973ZM12.75 8.99766H11.25C10.836 8.99766 10.5 9.33366 10.5 9.74766V11.2477C10.5 11.6617 10.836 11.9977 11.25 11.9977H12.75C13.164 11.9977 13.5 11.6617 13.5 11.2477V9.74766C13.5 9.33328 13.164 8.99766 12.75 8.99766ZM6.75 8.99766H5.25C4.836 8.99766 4.5 9.33366 4.5 9.74766V11.2477C4.5 11.6617 4.836 11.9977 5.25 11.9977H6.75C7.164 11.9977 7.5 11.6617 7.5 11.2477V9.74766C7.5 9.33328 7.164 8.99766 6.75 8.99766ZM6.75 14.9973H5.25C4.836 14.9973 4.5 15.3333 4.5 15.7473V17.2473C4.5 17.6617 4.836 17.9973 5.25 17.9973H6.75C7.164 17.9973 7.5 17.6617 7.5 17.2473V15.7473C7.5 15.3337 7.164 14.9973 6.75 14.9973Z"
              fill="#662D91"
            />
          </g>
          <defs>
            <clipPath id="clip0_2225_9004">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )
    case 'call':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 15.5C18.8 15.5 17.5 15.3 16.4 14.9H16.1C15.8 14.9 15.6 15 15.4 15.2L13.2 17.4C10.4 15.9 8 13.6 6.6 10.8L8.8 8.6C9.1 8.3 9.2 7.9 9 7.6C8.7 6.5 8.5 5.2 8.5 4C8.5 3.5 8 3 7.5 3H4C3.5 3 3 3.5 3 4C3 13.4 10.6 21 20 21C20.5 21 21 20.5 21 20V16.5C21 16 20.5 15.5 20 15.5ZM5 5H6.5C6.6 5.9 6.8 6.8 7 7.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5ZM19 19C17.7 18.9 16.4 18.6 15.2 18.2L16.4 17C17.2 17.2 18.1 17.4 19 17.4V19Z"
            fill="#662D91"
          />
        </svg>
      )
    case 'location':
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 3.05005C8.27344 3.05005 5.25 5.92771 5.25 9.47192C5.25 13.55 9.75 20.0127 11.4023 22.2449C11.4709 22.3391 11.5608 22.4158 11.6647 22.4687C11.7686 22.5215 11.8835 22.5491 12 22.5491C12.1165 22.5491 12.2314 22.5215 12.3353 22.4687C12.4392 22.4158 12.5291 22.3391 12.5977 22.2449C14.25 20.0136 18.75 13.5533 18.75 9.47192C18.75 5.92771 15.7266 3.05005 12 3.05005Z"
            stroke="#662D91"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 12.05C13.2426 12.05 14.25 11.0427 14.25 9.80005C14.25 8.55741 13.2426 7.55005 12 7.55005C10.7574 7.55005 9.75 8.55741 9.75 9.80005C9.75 11.0427 10.7574 12.05 12 12.05Z"
            stroke="#662D91"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    case 'chat':
      return (
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.66699 11.2167H13.3337M6.66699 7.05005H10.0003"
            stroke="#662D91"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M1.66699 2.4668V1.8418H1.04199V2.4668H1.66699ZM18.3337 2.4668H18.9587V1.8418H18.3337V2.4668ZM18.3337 15.8001V16.4251H18.9587V15.8001H18.3337ZM1.66699 15.8001H1.04199V16.4251H1.66699V15.8001ZM5.00033 19.1335H4.37533C4.37533 19.3737 4.51304 19.5927 4.72958 19.6968C4.94613 19.8009 5.20315 19.7716 5.39076 19.6215L5.00033 19.1335ZM5.00033 15.8001H5.62533V15.1751H5.00033V15.8001ZM9.16699 15.8001V15.1751H8.94774L8.77658 15.312L9.16699 15.8001ZM1.66699 3.0918H18.3337V1.8418H1.66699V3.0918ZM17.7087 2.4668V15.8001H18.9587V2.4668H17.7087ZM1.04199 2.4668V15.8001H2.29199V2.4668H1.04199ZM1.66699 16.4251H5.00033V15.1751H1.66699V16.4251ZM5.62533 19.1335V15.8001H4.37533V19.1335H5.62533ZM9.16699 16.4251H18.3337V15.1751H9.16699V16.4251ZM5.39076 19.6215L9.55741 16.2881L8.77658 15.312L4.60989 18.6454L5.39076 19.6215Z"
            fill="#662D91"
          />
        </svg>
      )
  }
}

export default Icon

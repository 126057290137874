import React from 'react'
import './styles/app.scss'
import Main from './Container/Main'
import { Route, Routes } from 'react-router-dom'
import publicRoutes from './routes/publicRoutes'
import NotFound from './Screens/404'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App(): JSX.Element {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />}>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.element />}
                key={index}
              />
            )
          })}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer theme="dark" />
    </>
  )
}

export default App

import React, { type FC } from 'react'
import Header from '../Components/Header'
import { Outlet } from 'react-router-dom'
import Menu from '../Components/Menu'
import { MobileMenuProvider } from '../context/mobileMenuContext'
import { BookingProvider } from '../context/bookingContext'

const Main: FC = () => {
  return (
    <BookingProvider>
      <MobileMenuProvider>
        <div>
          <Header />
          <Menu />
          <Outlet />
        </div>
      </MobileMenuProvider>
    </BookingProvider>
  )
}

export default Main

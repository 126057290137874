import React from 'react'
import WhiteLogo from '../../assets/svgs/HPlayWhite.svg'
import Instagram from '../../assets/svgs/instagram.svg'
import Twitter from '../../assets/images/xlogo.png'
import Facebook from '../../assets/svgs/facebook.svg'
import styles from './footer.module.css'
import { combineClassName } from '../../util/utilMethods'
import ArrowUp from '../../assets/svgs/arrow-up.svg'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Terms from '../../assets/docs/HEREL PLAY TERMS AND CONDITIONS OF USE.pdf'
import Privacy from '../../assets/docs/HEREL PLAY PRIVACY POLICY.pdf'
import Cookies from '../../assets/docs/HEREL PLAY COOKIE POLICY.pdf'

const Footer = (): JSX.Element => {
  return (
    <main
      className={combineClassName(
        'bg-black text-white p-3 pt-5 p-lg-5 mt-3',
        styles.wrapper
      )}
      id="footer"
    >
      <div
        className={styles.arrow_up}
        onClick={() => {
          window.scrollTo(0, 0)
        }}
      >
        <img src={ArrowUp} alt="arrow up" className="img-fluid" />
      </div>
      <div
        className={combineClassName(
          'd-flex justify-content-evenly align-items-start flex-wrap gap-2',
          styles.tops
        )}
      >
        <div
          className={combineClassName(
            'd-flex flex-column justify-content-between',
            styles.group
          )}
        >
          <div>
            <img
              src={WhiteLogo}
              alt="herel play logo"
              className="img img-fluid"
            />
          </div>
          <div
            className={combineClassName(
              'd-flex align-items-center gap-2 mb-3',
              styles.socials
            )}
          >
            <a
              target="blank"
              href="https://www.instagram.com/herel.play"
              className="text-decoration-none text-reset"
            >
              <img src={Instagram} alt="instagram logo" className="img-fluid" />
            </a>
            <a
              target="blank"
              href="https://twitter.com/HERELPlay"
              className={combineClassName(
                'text-decoration-none text-reset',
                styles.xlogo
              )}
            >
              <img
                src={Twitter}
                alt="twitter logo"
                className="img-fluid w-100 h-100"
              />
            </a>
            <a
              target="blank"
              href="https://web.facebook.com/profile.php?id=61550075475920"
              className="text-decoration-none text-reset"
            >
              <img src={Facebook} alt="facebook logo" className="img-fluid" />
            </a>
          </div>
        </div>
        <div className={styles.mobile_algin}>
          <p className="fw-bold">Company</p>
          <p
            onClick={() => {
              window.scrollTo(0, 0)
            }}
            className="cursor-pointer"
          >
            Home
          </p>
          <Link to="/bookings" className="text-decoration-none text-reset">
            <p>Bookings</p>
          </Link>
          <HashLink className="text-decoration-none text-reset" to="/#faq">
            <p>FAQs</p>
          </HashLink>
          <HashLink className="text-decoration-none text-reset" to="/#contact">
            <p>Contact</p>
          </HashLink>
        </div>
        <div className={styles.mobile_algin}>
          <p className="fw-bold">Legal</p>
          <p>
            <a href={Terms} className="text-decoration-none text-reset">
              Terms & Conditions
            </a>
          </p>
          <p>
            <a href={Privacy} className="text-decoration-none text-reset">
              Privacy Policy
            </a>
          </p>
          <p>
            <a href={Cookies} className="text-decoration-none text-reset">
              Cookies Policy
            </a>
          </p>
        </div>
        <div
          className={combineClassName(
            'd-flex flex-column justify-content-between',
            styles.group
          )}
        >
          <div>
            <p className="fw-bold">Contact</p>
            <p>
              Address : 14, Ruxton Road, Ikoyi
              <br />
              Lagos
            </p>
            <p>+2349169830299</p>
            <p>hello@herelplay.com</p>
          </div>
          <div className={styles.date}>
            <p>
              © {new Date().getFullYear()} HEREL PLAY. All rights reserved.
            </p>
          </div>
        </div>
      </div>
      <div
        className={combineClassName(
          'd-flex justify-content-between align-items-center mt-2 flex-wrap',
          styles.bottoms
        )}
      >
        {/* <ul className="list-unstyled d-flex gap-5 pt-3 flex-wrap">
          <li>Privacy Policy</li>
          <li>Terms of Use</li>
          <li>Legal</li>
          <li>Site Map</li>
        </ul> */}
      </div>
    </main>
  )
}

export default Footer

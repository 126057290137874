import React, { useState, type FC, useEffect } from 'react'
import styles from './dine.module.css'
// import Scroll from '../../assets/svgs/scroll.svg'
import { combineClassName, thousandSeparator } from '../../util/utilMethods'
import Icon from '../../Components/Icon'
import { Button } from 'react-bootstrap'
import WhatsappModal from '../../Components/WhatsappModal'
import * as XLSX from 'xlsx'
import PurpleImg from '../../assets/images/purple.png'

interface IMenu {
  title: string
  details: string
  price: string
}

const Dine: FC = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [menuPage, setMenuPage] = useState(1)
  const [showWhatsapp, setShowWhatsapp] = useState(false)

  const toggleWhatsappModal = (): void => {
    setShowWhatsapp(!showWhatsapp)
  }

  const [data, setData] = useState<Array<IMenu[]>>([])

  useEffect(() => {
    fetch('assets/herel_play_menu.xlsx')
      .then(async (response) => await response.arrayBuffer())
      .then((arrayBuffer) => {
        const workbook = XLSX.read(arrayBuffer, { type: 'buffer' })
        const sheetNames = workbook.SheetNames

        const sheetsData: any[] = []
        for (let i = 0; i < Math.min(2, sheetNames.length); i++) {
          const sheetData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetNames[i]]
          )
          sheetsData.push(sheetData)
        }

        setData(sheetsData)
      })
      .catch((error) => {
        console.error('Error reading the XLSX file:', error)
      })
  }, [])
  return (
    <>
      <WhatsappModal
        show={showWhatsapp}
        onHide={toggleWhatsappModal}
        handleButtonClck={toggleWhatsappModal}
      />
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <section className={styles.hero}>
            <h2>
              Enjoy Exquisite Flavours and
              <br />
              Impeccably Crafted Meals
            </h2>
            <p>Casual Dining Meals Made Just for You</p>

            <p style={{ marginTop: 0 }}>
              You can also order in to enjoy our restaurant experience from the
              comfort <br /> of your home or wherever you might be by calling
              +2349169830299 or
            </p>

            <Button className={styles.grid_btn} onClick={toggleWhatsappModal}>
              Order Now
            </Button>
            {/* <div className={styles.scroll_wrapper}>
            <div className={styles.scroll_image}>
              <img src={Scroll} alt="scroll_icon" className="w-100 img-fluid" />
            </div>
            <p className={styles.scroll_text}>Scroll</p>
          </div> */}
          </section>
          <section className={styles.slide_wrapper}>
            <div
              className={combineClassName(styles.card1, styles.card)}
              onClick={() => {
                setMenuPage(1)
                setShowMenu(true)
              }}
            >
              <p className={styles.card_text}>
                <span>FOOD MENU</span>
                <span>
                  <Icon name="arrow-right" />
                </span>
              </p>
            </div>
            <div
              className={combineClassName(styles.card2, styles.card)}
              onClick={() => {
                setMenuPage(0)
                setShowMenu(true)
              }}
            >
              <p className={styles.card_text}>
                <span>DRINKS MENU</span>
                <span>
                  <Icon name="arrow-right" />
                </span>
              </p>
            </div>
          </section>
          <section
            className={combineClassName(
              'd-flex',
              styles.details,
              showMenu ? '' : 'd-none'
            )}
          >
            <div className={styles.details_left}>
              <h2>{menuPage === 1 ? 'Food' : 'Drinks'} Menu</h2>
              <Button
                className={styles.details_left_btn}
                onClick={toggleWhatsappModal}
              >
                Book Now
              </Button>
            </div>
            <div className={styles.details_right}>
              <p
                onClick={() => {
                  setShowMenu(false)
                }}
              >
                <span>&#10006;</span>
              </p>
              <div
                className={combineClassName(styles.menu_items, 'text-start')}
              >
                {data[menuPage]?.map((item: IMenu, i: number) => {
                  if (item?.title) {
                    return (
                      <h2 className="text-center" key={i}>
                        {item?.title}
                      </h2>
                    )
                  } else {
                    const a = item?.details?.split(':')
                    return (
                      <div
                        key={i}
                        className={combineClassName(
                          'd-flex justify-content-between align-items-center mb-2 gap-2'
                        )}
                      >
                        <div className="d-flex gap-3">
                          <img
                            src={PurpleImg}
                            alt="food image"
                            className={combineClassName(
                              styles.menu_img,
                              'rounded-circle img-fluid'
                            )}
                          />
                          <p className="align-self-end">
                            {a[0]}
                            <br />
                            <span className={styles.description}>
                              {a[1]?.replaceAll(',', '/')}
                            </span>
                          </p>
                        </div>
                        <p className={combineClassName(styles.price)}>
                          {item?.price ? (
                            <span>&#8358;{thousandSeparator(item?.price)}</span>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  )
}

export default Dine

/* eslint-disable no-mixed-spaces-and-tabs */
import React, {
  type ChangeEvent,
  type CSSProperties,
  type DetailedHTMLProps,
  useState,
  type FC,
  type TextareaHTMLAttributes,
} from 'react'
import { combineClassName } from '../../util/utilMethods'
import styles from './TextArea.module.css'

interface TextAreaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  /**
   * The input label which appears at the top
   */
  inputLabel?: string

  /**
   * The html textarea wrapper ccs style
   */
  containerStyle?: CSSProperties
  labelClassName?: string
  /**
   * The html textarea wrapper ccs class name
   */
  containerClassName?: string

  validation?: boolean

  errorMessage?: string

  handleTextChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

const TextArea: FC<TextAreaProps> = ({
  inputLabel,
  handleTextChange,
  className,
  labelClassName,
  containerStyle,
  containerClassName,
  validation = true,
  errorMessage,
  ...props
}) => {
  const [touched, setTouched] = useState(false)
  return (
    <div className={containerClassName} style={containerStyle}>
      {inputLabel ? (
        <p className={combineClassName(styles.text_area_label, labelClassName)}>
          {inputLabel}
        </p>
      ) : null}
      <textarea
        {...props}
        onChange={handleTextChange}
        onBlur={() => {
          setTouched(true)
        }}
        className={combineClassName(styles.text_area, className)}
      />
      {validation && touched ? (
        <span className={styles.valid_errormessage}>{errorMessage}</span>
      ) : null}
    </div>
  )
}

export default TextArea

/**
 * Combine multiple class names
 * @param args css classes
 * @returns combine classes string separated with space
 */
export const combineClassName = (...args: Array<string | undefined | null>): string => {
    return args.join(' ');
};

export const phoneNumberValidation = (value: string): string => value?.replace(/\D/g, '')

export function convertTime(time: any): string {
    // Check correct time format and split into components
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time?.length > 1) { // If time format correct
        time = time.slice(1);// Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}

export function thousandSeparator(x: string): string {
	return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

import React, { type FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import SuccessGif from '../../assets/gifs/check.gif'
import styles from './successmodal.module.css'
import { combineClassName } from '../../util/utilMethods'

interface SuccessModalProps {
  title?: string
  description?: string
  buttonText?: string
  show: boolean
  onHide: () => any
}

const SuccessModal: FC<SuccessModalProps> = ({
  title = 'Success!',
  description = 'Changes saved successfully',
  buttonText = 'Okay',
  show,
  onHide,
}) => {
  return (
    <Modal centered show={show} onHide={onHide} dialogClassName={styles.dialog}>
      <div className="d-flex flex-column align-items-center p-4">
        <div className={styles.gif_wrapper}>
          <img
            src={SuccessGif}
            alt="success gif"
            className="h-100 w-100 img-fluid"
          />
        </div>
        <h2 className={combineClassName('text-primary', styles.title)}>
          {title}
        </h2>
        <p className={styles.descrption}>{description}</p>
      </div>
      <Modal.Footer className="d-flex justify-content-center">
        <Button className={styles.success_btn} onClick={onHide}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SuccessModal

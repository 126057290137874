import React, { type ReactNode, createContext, useReducer } from 'react'
import BookingReducer, { bookingInitialState } from './reducer'
import { type IBookingState } from '../../interface/booking'
import { CLEAR_BOOKING, SET_BOOKING_VALUE } from './types'

interface IBookingDispatch {
  setBookingValue: (value: Record<string, string>) => void
  clearBooking: () => void
}

export const BookingContext = createContext<IBookingState>(bookingInitialState)
export const BookingDispatch = createContext<IBookingDispatch>({
  setBookingValue: (value) => {},
  clearBooking: () => {},
})

export const BookingProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [state, dispatch] = useReducer(BookingReducer, bookingInitialState)

  const setBookingValue = (payload: Record<string, string>): void => {
    dispatch({ type: SET_BOOKING_VALUE, payload })
  }

  const clearBooking = (): void => {
    dispatch({ type: CLEAR_BOOKING })
  }

  return (
    <BookingContext.Provider
      value={{
        ...state,
      }}
    >
      <BookingDispatch.Provider
        value={{
          setBookingValue,
          clearBooking,
        }}
      >
        {children}
      </BookingDispatch.Provider>
    </BookingContext.Provider>
  )
}
